import LanguageDetector from 'i18next-browser-languagedetector'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import enTrans from './en.json'
import zhCnTrans from './zh-cn.json'
import koTrans from './ko.json'
import zhTwTrans from './zh-tw.json'
import jaTrans from './ja.json'

i18n
  .use(LanguageDetector) // 嗅探当前浏览器语言
  .use(initReactI18next) // init i18next
  .init({
    // 引入资源文件
    resources: {
      en: {
        translation: enTrans,
      },
      zhcn: {
        translation: zhCnTrans,
      },
      ko: {
        translation: koTrans,
      },
      zhtw: {
        translation: zhTwTrans,
      },
      ja: {
        translation: jaTrans,
      },
    },
    // 选择默认语言，选择内容为上述配置中的key
    fallbackLng: 'en',
    debug: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  })

export default i18n
